<template>
  <li class="accordionItem" ref="acdItem">
    <div class="accordionTrigger" :class="{accordionTriggerActive: visible}" @click="open">
      <slot name="accordion-trigger"></slot>
    </div>
    <transition :css="false" @enter="start" @leave="end">
      <div class="accordionContent" v-if="visible">
        <ul>
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
  import {TweenMax, Power2} from "gsap/all";
  export default {
    name: "AccordionItem",
    props: {},
    inject: ["Accordion"],
    data() {
      return {
        active: false,
        index: null
      };
    },
    computed: {
      visible() {
        return this.index === this.Accordion.active;
      }
    },
    methods: {
      open() {
        if (this.visible) {
          this.Accordion.active = '';
        } else {
          this.Accordion.active = '';
          this.Accordion.active = this.index;
        }
        this.active = this.visible;
      },
      start(el, done) {
        TweenMax.from(el, 0.5, {
          ease: Power2.easeInOut,
          height: 0,
          onComplete: () => {
            done();
          }
        });
      },
      end(el, done) {
        TweenMax.to(el, 0.5, {
          ease: Power2.easeInOut,
          height: 0,
          onComplete: () => {
            done();
          }
        });
      }
    },
    created() {
      this.index = this.Accordion.count++;
    }
  };
</script>

<style lang="scss" scoped>
  .accordionContent {
    overflow: hidden;
  }

  .accordionTrigger {
    cursor: pointer;
  }

  .accordionItem {
    border-bottom: 1px solid #ddd;
    position: relative;
  }

  .accordion-enter-active,
  .accordion-leave-active {
    transition: height 0.3s ease, opacity 0.3s ease;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }

  .accordionTrigger.accordionTriggerActive {
    .acc-tit {
      color: #ee2c3c;
      &:after {
        background: url(../../assets/images/career/acc-on.svg) no-repeat center/cover;
      }
    }
  }
</style>
