<template>
  <span>
    <button type="button" @click="quantityFn('btnReduction')">
      <span>감소</span>
    </button>
    <input type="text" ref="number" @change="quantityFn" :value="value" />
    <button type="button" @click="quantityFn('btnIncrease')">
      <span>증가</span>
    </button>
  </span>
</template>
<script>
  export default {
    name: "Quantity",
    props: ["value", "max", "min"],
    computed: {},
    mounted() {
      this.init();
    },
    model: {
      event: "change"
    },
    methods: {
      init() {
        if (this.min) {
          if (this.min < this.value) {
            this.$refs.number.value = this.value;
          } else {
            this.$refs.number.value = this.min;
          }
        }
      },
      quantityFn(type) {
        const input = this.$refs.number;
        let inputVal = Number(input.value);

        if (type === "btnIncrease") {
          inputVal = inputVal + 1;
        }

        if (type === "btnReduction") {
          inputVal = inputVal - 1;
        }
        if (isNaN(inputVal) || inputVal < 0 || inputVal < this.min) {
          inputVal = this.min ? this.min : 0;
        }
        if (inputVal > this.max) {
          inputVal = this.max;
        }
        input.value = inputVal;
        this.$emit("change", inputVal);
      }
    }
  };
</script>
<style scoped></style>
