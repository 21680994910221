<template>
  <label
    :class="{
      'form-radio': true,
      disabled: this.disabled,
      checked: this.checked,
      focused: focused
    }"
  >
    <input
      ref="radio"
      type="radio"
      :checked="checked"
      :disabled="disabled"
      :name="name"
      :value="val"
      @change="$emit('change', val, $event)"
      @blur="focused = false"
      @focus="focused = true"
    />
    <i></i>
    <span>
      <slot></slot>
    </span>
  </label>
</template>
<script>
  export default {
    name: "Radio",
    data() {
      return {
        focused: false
      };
    },
    computed: {
      checked() {
        return this.val === this.value;
      }
    },
    model: {
      event: "change"
    },
    props: {
      value: String,
      val: String,
      name: String,
      disabled: Boolean
    }
  };
</script>
<style lang="scss" scoped>
  label {
    height: 53px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 26px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: -0.05em;
    transition: all 200ms ease-in-out 0s;

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
    }
    &.disabled {
      cursor: default;
      color: #ddd;
    }
    &.checked {
      border-color: $red;
      background: $red;
      color: #fff;
      &.disabled {
        opacity: 0.5;
      }
    }
  }
</style>
