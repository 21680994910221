<template>
  <component :is="AppLayout" :key="$route.meta.layout" />
</template>
<script>
  // import style
  import "swiper/css/swiper.css";
  import {layouts} from "@/utils/global-methods";
  import {commonMethods} from "@/utils/common-methods";
  export default {
    name: "App",
    metaInfo() {
      return {
        title: "e · motion",
        titleTemplate: "%s | Full service Digital Agency",
        meta: [
          {
            name: "title",
            content: "이모션글로벌 | MAKE GREATNESS emotion global"
          },
          {
            name: "description",
            content:
              "UX컨설팅/소프트웨어 자문/개발 및 공급/어플리케이션 제작/웹, 앱디자인 등 디지털 전반의 모든 서비스를 제공하는 Full service Digital Agency입니다."
          },
          {
            name: "keywords",
            content:
              "이모션, 이모션글로벌, emotion, emotion global, FSN, UX컨설팅, UIUX, 사이트제작, 사이트제작업체, 웹사이트제작, 웹사이트제작업체, 모바일웹제작, 모바일웹사이트제작, 모바일앱제작, 어플리케이션제작, 어플제작, 앱개발, 앱개발업체, 어플제작업체, 어플리케이션제작업체, 웹디자인, 앱디자인, 반응형웹제작, 반응형사이트제작, UI/UX 디자인, 쇼핑몰웹사이트, 쇼핑몰디자인, 반응형 홈페이지, 홈페이지제작, 홈페이지리뉴얼, 디자인 에이전시 , 국내웹에이전시, 웹에이전시, 개발에이전시, UX/UI Design, 소프트웨어개발, 브랜드사이트제작, 회사홈페이지제작, 회사사이트제작, 크리에이티브 디자인, 디자인컨설팅, UX, CX, BX, UX Consulting, CX Consulting UX리서치, UX Research, UX전략, UX Strategy, 사용자경험, 사용자경험디자인, Experience Design, GUI, GUI design, BI, CI, Brand identity Design, Interaction Design, Strategy Planning, Creative idea, Creative Development, Total Management, SNS Marketing, Digital Transformation, eXperience"
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:title",
            content: "이모션글로벌 | MAKE GREATNESS emotion global",
            vmid: "og:title"
          },
          {
            property: "og:description",
            content:
              "UX컨설팅/소프트웨어 자문/개발 및 공급/어플리케이션 제작/웹, 앱디자인 등 디지털 전반의 모든 서비스를 제공하는 Full service Digital Agency입니다.",
            vmid: "og:description"
          },
          {
            property: "og:image",
            content: `${process.env.VUE_APP_URL}/pc/img/emotion_share.png`,
            vmid: "og:image"
          },
          {
            property: "og:url",
            content: process.env.VUE_APP_URL,
            vmid: "og:url"
          }
        ]
      };
    },
    mixins: [commonMethods],
    computed: {
      AppLayout() {
        return `Layout${this.$route.meta.layout || "Default"}`;
      }
    },
    components: {
      LayoutDefault: layouts("app-default"),
      LayoutClean: layouts("app-clean")
    },
    methods: {
      urlCheck() {
        const filter = "win16|win32|win64|macintel|mac|";
        if (navigator.platform) {
          if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
            // if (window.location.hostname === "dev.emotion.co.kr") {
            //   document.location = `http://devm.emotion.co.kr${window.location.pathname}${window.location.search}`;
            // }
            if (window.location.hostname === "www.emotion.co.kr") {
              document.location = `https://m.emotion.co.kr${window.location.pathname}${window.location.search}`;
            }
          }
        }
      }
    },
    created() {
      //console.log(`${process.env.VUE_APP_URL}/emotion_share.png`);
      this.urlCheck();
    }
  };
</script>
<style lang="scss" src="./assets/scss/common.scss" />
