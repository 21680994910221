var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkedType)?_c('label',{class:{
    'form-checkbox': true,
    disabled: _vm.disabled,
    checked: _vm.checked,
    focused: _vm.focused
  }},[_c('input',{ref:"checkbox",attrs:{"type":"checkbox","disabled":_vm.disabled,"required":_vm.required},domProps:{"checked":_vm.checked},on:{"change":function($event){return _vm.$emit('change', $event.target.checked, $event)},"blur":function($event){_vm.focused = false},"focus":function($event){_vm.focused = true}}}),_vm._t("default")],2):_c('label',{class:{
    'form-radio': true,
    disabled: this.disabled,
    checked: this.checked.some(function (el) { return el === _vm.val; }),
    focused: _vm.focused
  }},[_c('input',{ref:"checkbox",attrs:{"type":"checkbox","disabled":_vm.disabled,"required":_vm.required},domProps:{"checked":_vm.checked.some(function (el) { return el === _vm.val; })},on:{"change":_vm.handleChange,"blur":function($event){_vm.focused = false},"focus":function($event){_vm.focused = true}}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }