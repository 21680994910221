<template>
  <form-checkbox :checked="checked" @change="handleChange"> 전체선택 </form-checkbox>
</template>
<script>
  export default {
    name: "CheckboxAll",
    data() {
      return {
        focused: false
      };
    },
    model: {
      prop: "checked",
      event: "change"
    },
    watch: {
      checkedArray() {
        this.$emit("change", this.checkboxList.length === this.checkedArray.length);
      }
    },
    props: {
      checked: Boolean,
      disabled: Boolean,
      checkboxList: Array,
      checkedArray: Array
    },
    methods: {
      handleChange(val, e) {
        this.$emit("update:checkedArray", e.target.checked ? this.checkboxList : []);
        this.$emit("change", e.target.checked);
      }
    }
  };
</script>
<style scoped></style>
